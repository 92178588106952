/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'suitcase-lg': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 2a2 2 0 012-2h2a2 2 0 012 2h3.5A1.5 1.5 0 0116 3.5v9a1.5 1.5 0 01-1.5 1.5H14a.5.5 0 01-1 0H3a.5.5 0 01-1 0h-.5A1.5 1.5 0 010 12.5v-9A1.5 1.5 0 011.5 2zm1 0h4a1 1 0 00-1-1H7a1 1 0 00-1 1M1.5 3a.5.5 0 00-.5.5v9a.5.5 0 00.5.5H3V3zM15 12.5v-9a.5.5 0 00-.5-.5H13v10h1.5a.5.5 0 00.5-.5m-3 .5V3H4v10z"/>',
    },
});
